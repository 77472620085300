<div class="menu">
  <mat-toolbar color="primary">
    <mat-toolbar class="logo-box" routerLink="/">
      <img class="logo" alt="DailyTech Logo" src="assets/blueColorTriomphe.png" />
    </mat-toolbar>
    <div fxHide.gt-xs>
      <button mat-icon-button (click)="onToggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    
    <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs id="menu-list">
      <mat-nav-list fxLayout fxLayoutGap="10px" class="navigation-items">

        <a mat-list-item routerLink="/writing" class="nav-link daily-tech monoton" *ngIf="(isAuth$ | async ) || adminAuthService.isAdminLoggedIn()"
          >WRITE!</a
        >
        <a
          mat-list-item
          *ngIf="adminAuthService.isAdminLoggedIn()"
          routerLink="/admin/user"
          class="nav-link daily-tech monoton"
          >ADMIN CONSOLE</a
        >
 
        <a mat-list-item routerLink="" class="nav-link daily-tech monoton"
          >BLOG</a
        >
        <a mat-list-item routerLink="/news" class="nav-link daily-tech monoton"
        >NEWS</a
      >
      <a mat-list-item routerLink="/nasa" class="nav-link daily-tech monoton"
        >NASA</a
      >
        <a
          mat-list-item
          *ngIf="!adminAuthService.isAdminLoggedIn() && !(isAuth$ | async )"
          routerLink="/login"
          class="nav-link daily-tech monoton"
          >LOGIN</a
        >
        <a
          mat-list-item
          class="pointer"
          *ngIf="(isAuth$ | async ) || adminAuthService.isAdminLoggedIn()"
          (click)="onLogout(); adminAuthService.logout()"
          routerLink="/login"
          class="nav-link daily-tech monoton"
          >SIGN OUT</a
        >
      </mat-nav-list>
    </div>
  </mat-toolbar>
</div>

