<section class="new-writing" fxLayout fxLayoutAlign="center">
    <form   (ngSubmit)="onStartWriting(f)" #f="ngForm">
       <div class="menu">
        <mat-card fxFlex.xs="100%" fxFlex="500px">
          <mat-card-title fxLayoutAlign="center">Time to Write!</mat-card-title>
          <mat-card-content fxLayoutAlign="center">
              <mat-form-field  class="white" *ngIf="!isLoading && writingBlogs$ | async">
                  <mat-select placeholder="Category" ngModel name="writing" required>
                      <mat-option *ngFor="let writing of writingBlogs$ | async" [value]="writing.id">
                          {{ writing.name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-spinner *ngIf="isLoading"></mat-spinner>
          </mat-card-content>
          <mat-card-actions fxLayoutAlign="center" *ngIf="!isLoading">
              <button  *ngIf="writingBlogs$ | async" type="submit" mat-raised-button  [disabled]="f.invalid">Start</button>
              <button  *ngIf="!(writingBlogs$ | async)" type="button" mat-raised-button  (click)="fetchWritings()">Fetch Again</button>
          </mat-card-actions>
      </mat-card>
       </div>
</form>
</section>
