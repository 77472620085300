<div fxLayoutAlign="center center">
    <mat-form-field fxFlex="40%">
        <!-- <input type="text" (keyup)="blogFilter($event.target.value)" placeholder="Blog Filter"> -->
      <input matInput type="text" (keyup)="blogFilter($event.target.value)" placeholder="Blog Filter">
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.date.toDate() | date:'yyyy/MM/dd h:mm:ss a'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="wordCount">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Word Count</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.wordCount | number }}</mat-cell>
    </ng-container>

            <ng-container matColumnDef="durationGoal">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Duration</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.durationGoal | number }}</mat-cell>
            </ng-container>

    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.state }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns:displayedColumns; "></mat-row>

  </mat-table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[1, 5, 10, 20,30,40]">
  </mat-paginator>
